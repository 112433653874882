import MockAdapter from 'axios-mock-adapter'
import {cloneDeep} from 'lodash'

import {Kpi, ProcessStage} from '../../modules/kpi-calculation/types'
import {mockStore} from '../mockStore'

import {numberRegEx, sleepResponse, saveScenario} from './utils'

export const enableKpisEndpoints = (mock: MockAdapter) => {
  mock.onGet(new RegExp(`^/kpieditor/plants/${numberRegEx}/kpis$`)).reply(() => {
    const {
      burglengenfeld: {kpis}
    } = mockStore.scenario()

    return sleepResponse([200, kpis])
  })

  mock.onGet(new RegExp(`^/kpieditor/plants/${numberRegEx}/sensors$`)).reply(() => {
    const {
      burglengenfeld: {sensors}
    } = mockStore.scenario()

    return sleepResponse([200, sensors])
  })

  mock.onGet(new RegExp(`^/kpieditor/plants/${numberRegEx}/functions$`)).reply(() => {
    const {
      burglengenfeld: {functions}
    } = mockStore.scenario()

    return sleepResponse([200, functions])
  })

  mock
    .onGet(new RegExp(`^/kpieditor/plants/${numberRegEx}/kpis/${numberRegEx}/units$`))
    .reply(() => {
      const {
        burglengenfeld: {kpiUnits}
      } = mockStore.scenario()

      return sleepResponse([200, kpiUnits])
    })

  const modifyKPI = (processStages: ProcessStage[], kpiDto: Kpi, inputKpiId: number) => {
    const editedProcessStages = cloneDeep(processStages)
    for (const processStage of editedProcessStages) {
      const matchedKpiIndex = processStage.definitionsWithResults.findIndex(
        (kpi) => kpi.definition.kpiDefinitionId === inputKpiId
      )
      if (matchedKpiIndex >= 0) {
        processStage.definitionsWithResults[matchedKpiIndex] = {
          ...processStage.definitionsWithResults[matchedKpiIndex],
          ...kpiDto
        }
        return editedProcessStages
      }
    }
    return editedProcessStages
  }

  // PATCH edit kpi display name
  mock
    .onPatch(new RegExp(`^/kpieditor/plants/${numberRegEx}/kpis/${numberRegEx}/displayname$`))
    .reply((config) => {
      const kpiId = Number((config.url as string).split('/')[5])
      const kpiDto = JSON.parse(config.data as string) as Kpi
      const scenario = mockStore.scenario()

      const editedProcessStages = modifyKPI(
        scenario.burglengenfeld.kpis.processStages,
        kpiDto,
        kpiId
      )

      scenario.burglengenfeld.kpis.processStages = editedProcessStages
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // PATCH edit kpi display name
  mock
    .onPatch(new RegExp(`^/kpieditor/plants/${numberRegEx}/kpis/${numberRegEx}/formula$`))
    .reply((config) => {
      const kpiId = Number((config.url as string).split('/')[5])
      const kpiDto = JSON.parse(config.data as string) as Kpi
      const scenario = mockStore.scenario()

      const editedProcessStages = modifyKPI(
        scenario.burglengenfeld.kpis.processStages,
        kpiDto,
        kpiId
      )

      scenario.burglengenfeld.kpis.processStages = editedProcessStages
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // PATCH edit kpi unit
  mock
    .onPatch(new RegExp(`^/kpieditor/plants/${numberRegEx}/kpis/${numberRegEx}/unit$`))
    .reply((config) => {
      const kpiId = Number((config.url as string).split('/')[5])
      const kpiDto = JSON.parse(config.data as string) as Kpi
      const scenario = mockStore.scenario()

      const editedProcessStages = modifyKPI(
        scenario.burglengenfeld.kpis.processStages,
        kpiDto,
        kpiId
      )

      scenario.burglengenfeld.kpis.processStages = editedProcessStages
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // PATCH edit kpi time step
  mock
    .onPatch(new RegExp(`^/kpieditor/plants/${numberRegEx}/kpis/${numberRegEx}/frequency$`))
    .reply((config) => {
      const kpiId = Number((config.url as string).split('/')[5])
      const kpiDto = JSON.parse(config.data as string) as Kpi
      const scenario = mockStore.scenario()

      const editedProcessStages = modifyKPI(
        scenario.burglengenfeld.kpis.processStages,
        kpiDto,
        kpiId
      )

      scenario.burglengenfeld.kpis.processStages = editedProcessStages
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // PATCH edit kpi thresholds
  mock
    .onPatch(new RegExp(`^/kpieditor/plants/${numberRegEx}/kpis/${numberRegEx}/ranges$`))
    .reply((config) => {
      const kpiId = Number((config.url as string).split('/')[5])
      const kpiDto = JSON.parse(config.data as string) as Kpi
      const scenario = mockStore.scenario()

      const editedProcessStages = modifyKPI(
        scenario.burglengenfeld.kpis.processStages,
        kpiDto,
        kpiId
      )

      scenario.burglengenfeld.kpis.processStages = editedProcessStages
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  mock
    .onGet(new RegExp(`^/kpieditor/plants/${numberRegEx}/kpis/${numberRegEx}/last-results$`))
    .reply(() => {
      const {
        burglengenfeld: {lastResults}
      } = mockStore.scenario()

      return sleepResponse([200, lastResults])
    })
}
