import {AxiosInstance} from 'axios'

import {MockState, MOCK_REQUEST_TIMEOUT_KEY} from '../mockState'
import {mockStore} from '../mockStore'

export const sleepResponse = <Resolution extends [code: number, data: unknown]>(
  resolution: Resolution
): Promise<Resolution> => {
  const requestTimeout: string | null = window.sessionStorage.getItem(MOCK_REQUEST_TIMEOUT_KEY)
  return new Promise((resolve) => {
    return setTimeout(() => resolve(resolution), requestTimeout ? parseInt(requestTimeout, 10) : 25)
  })
}

export const enableLogging = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((request) => {
    const {url, params, data} = request
    console.info(`[MOCK] Request received for ${url}`, {params, data})
    return request
  })
  axiosInstance.interceptors.response.use((response) => {
    const {
      status,
      data,
      request: {responseURL}
    } = response
    console.info(`[MOCK] Sending ${status} response for '${responseURL}'`, {data})
    return response
  })
}

export function saveScenario(state: MockState) {
  mockStore.setScenarioData(state)
}

export const uuidRegEx = '\\b[0-9a-f]{8}\\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\\b[0-9a-f]{12}\\b'
export const numberRegEx = '\\d+'
