import MockAdapter from 'axios-mock-adapter'
import {sortBy} from 'lodash'

import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

import {MeasurementTypesMapping} from '@settings/modules/measurements/types/MeasurementTypesMapping'

export const enableMeasurementsEndpoints = (mock: MockAdapter) => {
  // GET global measurement types categories
  mock.onGet(new RegExp('^/plants/materials/global-measurement-types/categories$')).reply(() => {
    const {
      burglengenfeld: {globalMeasurementTypesCategories}
    } = mockStore.scenario()

    return sleepResponse([200, globalMeasurementTypesCategories])
  })

  // GET global measurement types
  mock.onGet(new RegExp('^/plants/materials/global-measurement-types$')).reply(() => {
    const {
      burglengenfeld: {globalMeasurementTypes}
    } = mockStore.scenario()

    return sleepResponse([200, globalMeasurementTypes])
  })

  // GET lims measurement types
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/lims/measurement-types$`)).reply(() => {
    const {
      burglengenfeld: {limsMeasurementTypes}
    } = mockStore.scenario()

    return sleepResponse([200, limsMeasurementTypes])
  })

  // GET measurement types mappings
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/measurement-types$`)).reply(() => {
    const {
      burglengenfeld: {measurementTypesMappings}
    } = mockStore.scenario()

    return sleepResponse([200, measurementTypesMappings])
  })

  // POST map lims measurement type to the global measurement type
  mock
    .onPost(new RegExp(`^/plants/${numberRegEx}/materials/measurement-types$`))
    .reply((config) => {
      const scenario = mockStore.scenario()

      // Getting the provided payload
      const providedPayload = JSON.parse(config.data as string) as Omit<
        MeasurementTypesMapping,
        'id'
      >

      // Getting the latest measurementTypesMapping
      const latestMeasurementTypesMapping = sortBy(
        scenario.burglengenfeld.measurementTypesMappings.sort(),
        ['id']
      ).pop()

      // Adding info about new mapping
      scenario.burglengenfeld.measurementTypesMappings.push({
        id: latestMeasurementTypesMapping ? latestMeasurementTypesMapping.id + 1 : 0,
        ...providedPayload
      })

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // DELETE unmap lims measurement type from the global measurement type
  mock
    .onDelete(new RegExp(`^/plants/${numberRegEx}/materials/measurement-types/${numberRegEx}$`))
    .reply((config) => {
      const scenario = mockStore.scenario()

      // Getting measurement types mapping id that is being deleted
      const deletingId = Number((config.url as string).split('/')[5])

      // Removing measurement types mapping that was unmapped
      scenario.burglengenfeld.measurementTypesMappings =
        scenario.burglengenfeld.measurementTypesMappings.filter(
          (mapping) => mapping.id !== deletingId
        )

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  return mock
}
