import MockAdapter from 'axios-mock-adapter'

import {ProductForecastStatus} from '../../modules/common/enums'
import {mockStore} from '../mockStore'

import {numberRegEx, saveScenario, sleepResponse} from './utils'

export const enableSalesForecastEndpoints = (mock: MockAdapter) => {
  mock
    .onGet(
      new RegExp(`^/plants/${numberRegEx}/material-demand/${numberRegEx}/demand/prediction/status$`)
    )
    .reply(() => {
      const {
        burglengenfeld: {productsSalesForecastInfo}
      } = mockStore.scenario()

      return sleepResponse([200, productsSalesForecastInfo])
    })

  mock
    .onPost(
      new RegExp(
        `^/plants/${numberRegEx}/material-demand/${numberRegEx}/demand/prediction/${numberRegEx}$`
      )
    )
    .reply(({url}) => {
      const productId = (url as string).split('/')[7]

      const scenario = mockStore.scenario()

      const updatedForecastInfo = {
        ...scenario.burglengenfeld.productsSalesForecastInfo,
        [productId]: {status: ProductForecastStatus.Requested}
      }

      scenario.burglengenfeld.productsSalesForecastInfo = updatedForecastInfo

      saveScenario(scenario)

      return sleepResponse([201, updatedForecastInfo])
    })

  return mock
}
