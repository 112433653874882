import type {AssetResponse} from '@hconnect/common/types'
import MockAdapter from 'axios-mock-adapter'
import moment from 'moment'

import type {
  AssetStandardOperationTime,
  AssetStandardOperationTimePayload,
  AssetsStandardOperationTimes,
  DefaultAssetOperationTimePayload,
  DefaultAssetsOperationTimes
} from '../../modules/assets/types'
import {Schedule, ScheduleItem} from '../../modules/common/types'
import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

const getAssetIdByOperationModeId = (assets: AssetResponse[], operationModeId: number) =>
  assets.find((asset) =>
    asset.operationModes.some((operationMode) => operationMode.id === operationModeId)
  )?.id as number

const findStandardOperationTimeById = (
  assetsStandardOperationTimes: AssetsStandardOperationTimes,
  id: number
) => {
  const standardOperationTime = Object.entries(assetsStandardOperationTimes)
    .map(([assetId, assetStandardOperationTimes]) =>
      assetStandardOperationTimes.map((assetStandardOperationTime) => ({
        assetId,
        assetStandardOperationTime
      }))
    )
    .flat()
    .find((e) => e.assetStandardOperationTime.id === id)
  if (!standardOperationTime) {
    throw new Error('Cannot find standard operation mode')
  }
  return standardOperationTime
}

export const enableSchedulerEndpoints = (mock: MockAdapter) => {
  // GET Schedule (C#)
  mock.onGet('/schedules/latest').reply((config) => {
    const {start, end} = config.params as {
      start: string
      end: string
    }

    const {
      burglengenfeld: {schedule}
    } = mockStore.scenario()

    const returnSchedule: Schedule = {
      ...schedule,
      schedules: Object.fromEntries(
        Object.entries(schedule.schedules).filter(([, scheduleItem]) => {
          return (
            moment.utc(scheduleItem.end).isAfter(moment.utc(start)) &&
            moment.utc(scheduleItem.start).isBefore(moment.utc(end))
          )
        })
      )
    }

    return sleepResponse([200, returnSchedule])
  })

  // GET default asset operation times (C#)
  mock.onGet(new RegExp(`/plants/${numberRegEx}/schedules/standard-operation-times`)).reply(() => {
    const {burglengenfeld} = mockStore.scenario()
    return sleepResponse([200, burglengenfeld.assetsStandardOperationTimes])
  })

  // POST default asset operation times (C#)
  mock
    .onPost(new RegExp(`/plants/${numberRegEx}/schedules/standard-operation-times`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {
          assetsStandardOperationTimes,
          validatedPostSTDRequests,
          standardTimesValidationErrorResponse
        }
      } = scenario

      const newData: AssetStandardOperationTimePayload = JSON.parse(
        config.data as string
      ) as AssetStandardOperationTimePayload

      if (config.params['onConflict'] === 'Error') {
        // validate endpoint
        if (!validatedPostSTDRequests[JSON.stringify(newData)]) {
          scenario.burglengenfeld.validatedPostSTDRequests[JSON.stringify(newData)] = true
          saveScenario(scenario)
          return sleepResponse([
            400,
            {
              type: 'https://api.hce.heidelbergcement.com/errors/badRequest',
              title: 'Bad Request',
              status: 400,
              detail: 'There are conflicts with existing Schedule Items',
              extendedData: standardTimesValidationErrorResponse
            }
          ])
        }
      }

      const {assetId} = newData

      const scheduleItems: ScheduleItem[] = [
        {
          id: Date.now() + '',
          start: '2022-01-01T03:00:00',
          end: '2022-01-01T04:00:00',
          assetOperationModeId: newData.operationModeId,
          isShutdownAvailable: false,
          isTransitionTime: false,
          assetId
        }
      ]

      const newDefaultOperationTime: AssetStandardOperationTime = {
        ...newData,
        id: Date.now(),
        occurrences: {
          scheduleItems,
          hasMore: false
        }
      }

      const newAssetsStandardOperationTimes: AssetsStandardOperationTimes = {
        ...assetsStandardOperationTimes,
        [assetId]: [
          ...(assetsStandardOperationTimes?.[assetId] ? assetsStandardOperationTimes[assetId] : []),
          newDefaultOperationTime
        ]
      }

      scenario.burglengenfeld.assetsStandardOperationTimes = newAssetsStandardOperationTimes
      saveScenario(scenario)

      return sleepResponse([201, {}])
    })

  // PUT default asset operation time (C#)
  mock
    .onPut(new RegExp(`/plants/${numberRegEx}/schedules/standard-operation-times/${numberRegEx}`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {
          assetsStandardOperationTimes,
          validatedPutSTDRequests,
          standardTimesValidationErrorResponse
        }
      } = scenario

      const assetStandardOperationTimeId = Number((config.url as string).split('/')[5])

      const editedData: AssetStandardOperationTimePayload = JSON.parse(
        config.data as string
      ) as AssetStandardOperationTimePayload

      if (config.params['onConflict'] === 'Error') {
        // validate endpoint
        if (!validatedPutSTDRequests[JSON.stringify(editedData)]) {
          scenario.burglengenfeld.validatedPutSTDRequests[JSON.stringify(editedData)] = true
          saveScenario(scenario)
          return sleepResponse([
            400,
            {
              type: 'https://api.hce.heidelbergcement.com/errors/badRequest',
              title: 'Bad Request',
              status: 400,
              detail: 'There are conflicts with existing Schedule Items',
              extendedData: standardTimesValidationErrorResponse
            }
          ])
        }
      }

      const standardOperationTime = findStandardOperationTimeById(
        assetsStandardOperationTimes,
        assetStandardOperationTimeId
      )
      const assetId = Number(standardOperationTime.assetId).valueOf()

      const scheduleItems: ScheduleItem[] = [
        {
          id: Date.now() + '',
          start: '2022-01-01T03:00:00',
          end: '2022-01-01T04:00:00',
          assetOperationModeId: editedData.operationModeId,
          isShutdownAvailable: false,
          isTransitionTime: false,
          assetId
        }
      ]

      const editedDefaultOperationTime = {
        ...editedData,
        occurrences: {
          scheduleItems,
          hasMore: false
        },
        id: assetStandardOperationTimeId
      }

      const newAssetsStandardOperationTimes: AssetsStandardOperationTimes = {
        ...assetsStandardOperationTimes,
        [assetId]: assetsStandardOperationTimes[assetId].map((assetStandardOperationTime) => {
          if (assetStandardOperationTime.id === assetStandardOperationTimeId) {
            return editedDefaultOperationTime
          }
          return assetStandardOperationTime
        })
      }

      scenario.burglengenfeld.assetsStandardOperationTimes = newAssetsStandardOperationTimes
      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  //  DELETE default asset operation time (C#)
  mock
    .onDelete(
      new RegExp(`/plants/${numberRegEx}/schedules/standard-operation-times/${numberRegEx}`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {assetsStandardOperationTimes}
      } = scenario

      const assetStandardOperationTimeId = Number((config.url as string).split('/')[5])
      const standardOperationTime = findStandardOperationTimeById(
        assetsStandardOperationTimes,
        assetStandardOperationTimeId
      )

      const assetId = Number(standardOperationTime.assetId).valueOf()

      const newAssetStandardOperationTimes: AssetsStandardOperationTimes = {
        ...assetsStandardOperationTimes,
        [assetId]: assetsStandardOperationTimes[assetId].filter((AssetStandardOperationTime) => {
          return AssetStandardOperationTime.id !== assetStandardOperationTimeId
        })
      }

      scenario.burglengenfeld.assetsStandardOperationTimes = newAssetStandardOperationTimes
      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  // GET default asset operation times (C#)
  mock.onGet(new RegExp(`/schedules/default-asset-operation-times/${numberRegEx}`)).reply(() => {
    const {burglengenfeld} = mockStore.scenario()
    return sleepResponse([200, burglengenfeld.defaultAssetsOperationTimes])
  })

  // POST default asset operation times (C#)
  mock
    .onPost(new RegExp(`/schedules/default-asset-operation-times/${numberRegEx}/${numberRegEx}`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {defaultAssetsOperationTimes, assets}
      } = scenario

      const assetOperationModeId = Number((config.url as string).split('/')[4])
      const assetId = getAssetIdByOperationModeId(assets, assetOperationModeId)

      const newData: DefaultAssetOperationTimePayload = JSON.parse(
        config.data as string
      ) as DefaultAssetOperationTimePayload

      const newDefaultOperationTime = {...newData, id: Date.now(), assetOperationModeId}

      const newDefaultAssetsOperationTimes: DefaultAssetsOperationTimes = {
        ...defaultAssetsOperationTimes,
        [assetId]: [
          ...(defaultAssetsOperationTimes?.[assetId] ? defaultAssetsOperationTimes[assetId] : []),
          newDefaultOperationTime
        ]
      }

      scenario.burglengenfeld.defaultAssetsOperationTimes = newDefaultAssetsOperationTimes
      saveScenario(scenario)

      return sleepResponse([201, {}])
    })

  // PUT default asset operation time (C#)
  mock
    .onPut(
      new RegExp(
        `/schedules/default-asset-operation-times/${numberRegEx}/${numberRegEx}/${numberRegEx}`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {defaultAssetsOperationTimes, assets}
      } = scenario

      const assetOperationModeId = Number((config.url as string).split('/')[4])
      const defaultAssetOperationTimeId = Number((config.url as string).split('/')[5])
      const assetId = getAssetIdByOperationModeId(assets, assetOperationModeId)

      const editedData: DefaultAssetOperationTimePayload = JSON.parse(
        config.data as string
      ) as DefaultAssetOperationTimePayload

      const editedDefaultOperationTime = {
        ...editedData,
        id: defaultAssetOperationTimeId,
        assetOperationModeId
      }

      const newDefaultAssetsOperationTimes: DefaultAssetsOperationTimes = {
        ...defaultAssetsOperationTimes,
        [assetId]: defaultAssetsOperationTimes[assetId].map((defaultAssetOperationTime) => {
          if (defaultAssetOperationTime.id === defaultAssetOperationTimeId) {
            return editedDefaultOperationTime
          }
          return defaultAssetOperationTime
        })
      }

      scenario.burglengenfeld.defaultAssetsOperationTimes = newDefaultAssetsOperationTimes
      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  //  DELETE default asset operation time (C#)
  mock
    .onDelete(
      new RegExp(
        `/schedules/default-asset-operation-times/${numberRegEx}/${numberRegEx}/${numberRegEx}`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const {
        burglengenfeld: {defaultAssetsOperationTimes, assets}
      } = scenario

      const assetOperationModeId = Number((config.url as string).split('/')[4])
      const defaultAssetOperationTimeId = Number((config.url as string).split('/')[5])
      const assetId = getAssetIdByOperationModeId(assets, assetOperationModeId)

      const newDefaultAssetOperationTimes: DefaultAssetsOperationTimes = {
        ...defaultAssetsOperationTimes,
        [assetId]: defaultAssetsOperationTimes[assetId].filter((defaultAssetOperationTime) => {
          return defaultAssetOperationTime.id !== defaultAssetOperationTimeId
        })
      }

      scenario.burglengenfeld.defaultAssetsOperationTimes = newDefaultAssetOperationTimes
      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  return mock
}
