import MockAdapter from 'axios-mock-adapter'

import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx} from './utils'

export const enableDSEndpoints = (mock: MockAdapter) => {
  // GET assets
  mock.onGet(new RegExp(`^/hcem/ui/v2/optimizer/${numberRegEx}/constraints$`)).reply(() => {
    const {
      burglengenfeld: {constraints}
    } = mockStore.scenario()

    return sleepResponse([200, constraints])
  })

  return mock
}
