import {
  DataConnectionType,
  DatamartSyncDetails,
  EmailSyncDetails,
  MaterialStorage,
  MaterialStorageDTO,
  Status,
  UnitOfMeasurement
} from '@hconnect/common/types'
import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

export const enableMaterialStorageEndpoints = (mock: MockAdapter) => {
  // GET material storage (C#)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/material-storage$`)).reply(() => {
    const {
      burglengenfeld: {materialStorage}
    } = mockStore.scenario()

    return sleepResponse([200, materialStorage])
  })

  // PATCH edit material storage (C#)
  const editStorageResourceRegEx =
    '(name|capacity|dead-stock|material|minimum-stock-level|minimum-weekend-stock-level|material-type|measurement-type|slack-cost-per-tonne|is-optimized|is-measured)'

  mock
    .onPatch(
      new RegExp(
        `^/plants/${numberRegEx}/material-storage/${numberRegEx}/${editStorageResourceRegEx}$`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const storageDTO = JSON.parse(config.data as string) as MaterialStorageDTO
      const storageId = Number((config.url as string).split('/')[4])

      const prevStorage = scenario.burglengenfeld.materialStorage.find(
        (storage) => storage.id === storageId
      ) as MaterialStorage

      const isStoredMaterialChanged = prevStorage.materialType !== storageDTO.materialType

      const editedStorage: MaterialStorage = {
        ...prevStorage,
        ...storageDTO,
        ...(isStoredMaterialChanged ? {materialId: null} : {}),
        status: Status.Edited,
        updatedOn: moment.utc().toISOString(),
        updatedBy: 'Test user'
      }

      scenario.burglengenfeld.materialStorage = scenario.burglengenfeld.materialStorage.map(
        (storage) => (storage.id === storageId ? editedStorage : storage)
      )
      saveScenario(scenario)
      return sleepResponse([200, editedStorage])
    })

  // POST add material storage (C#)
  mock.onPost(new RegExp(`^/plants/${numberRegEx}/material-storage$`)).reply((config) => {
    const scenario = mockStore.scenario()
    const storageDTO = JSON.parse(config.data as string) as MaterialStorageDTO

    const newStorage: MaterialStorage = {
      id: Date.now(),
      status: Status.Created,
      createdOn: moment.utc().toISOString(),
      createdBy: 'Test user',
      currentStorageLevel: {
        measuredAt: moment.utc().toISOString(),
        createdAt: moment.utc().toISOString(),
        level: 0
      },
      unitOfMeasurement: UnitOfMeasurement.Tonnes,
      emailSyncDetails: null,
      datamartSyncDetails: null,
      // default value returned by BE
      slackCostPerTonne: 1,
      ...storageDTO
    }
    scenario.burglengenfeld.materialStorage = [
      ...scenario.burglengenfeld.materialStorage,
      newStorage
    ]
    saveScenario(scenario)
    return sleepResponse([201, newStorage])
  })

  // DELETE material storage
  mock
    .onDelete(new RegExp(`^/plants/${numberRegEx}/material-storage/${numberRegEx}$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const storageId = Number((config.url as string).split('/')[4])

      scenario.burglengenfeld.materialStorage = scenario.burglengenfeld.materialStorage.filter(
        (storage) => storage.id !== storageId
      )

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // POST create new datamart connection
  mock
    .onPost(new RegExp(`^/plants/${numberRegEx}/material-storage/${numberRegEx}/details/datamart$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const storageId = Number((config.url as string).split('/')[4])
      const dto = JSON.parse(config.data as string) as DatamartSyncDetails

      const prevStorage = scenario.burglengenfeld.materialStorage.find(
        (storage) => storage.id === storageId
      ) as MaterialStorage

      const editedStorage: MaterialStorage = {
        ...prevStorage,
        datamartSyncDetails: dto,
        status: Status.Edited,
        updatedOn: moment.utc().toISOString(),
        updatedBy: 'Test user'
      }

      scenario.burglengenfeld.materialStorage = scenario.burglengenfeld.materialStorage.map(
        (storage) => (storage.id === storageId ? editedStorage : storage)
      )
      saveScenario(scenario)

      return sleepResponse([201, editedStorage])
    })

  // PATCH edit datamart connection
  mock
    .onPatch(
      new RegExp(
        `^/plants/${numberRegEx}/material-storage/${numberRegEx}/details/datamart/(tag-name|tonne-conversion-factor)$`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const storageId = Number((config.url as string).split('/')[4])
      const dto = JSON.parse(config.data as string) as DatamartSyncDetails

      const prevStorage = scenario.burglengenfeld.materialStorage.find(
        (storage) => storage.id === storageId
      ) as MaterialStorage

      const editedStorage: MaterialStorage = {
        ...prevStorage,
        datamartSyncDetails: dto,
        status: Status.Edited,
        updatedOn: moment.utc().toISOString(),
        updatedBy: 'Test user'
      }
      scenario.burglengenfeld.materialStorage = scenario.burglengenfeld.materialStorage.map(
        (storage) => (storage.id === storageId ? editedStorage : storage)
      )
      saveScenario(scenario)

      return sleepResponse([200, editedStorage])
    })

  // POST create new email parser connection
  mock
    .onPost(
      new RegExp(`^/plants/${numberRegEx}/material-storage/${numberRegEx}/details/email-parser$`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const storageId = Number((config.url as string).split('/')[4])
      const dto = JSON.parse(config.data as string) as EmailSyncDetails

      const prevStorage = scenario.burglengenfeld.materialStorage.find(
        (storage) => storage.id === storageId
      ) as MaterialStorage

      const editedStorage: MaterialStorage = {
        ...prevStorage,
        emailSyncDetails: dto,
        status: Status.Edited,
        updatedOn: moment.utc().toISOString(),
        updatedBy: 'Test user'
      }
      scenario.burglengenfeld.materialStorage = scenario.burglengenfeld.materialStorage.map(
        (storage) => (storage.id === storageId ? editedStorage : storage)
      )
      saveScenario(scenario)
      return sleepResponse([201, editedStorage])
    })

  // PATCH edit email parser connection
  mock
    .onPatch(
      new RegExp(
        `^/plants/${numberRegEx}/material-storage/${numberRegEx}/details/email-parser/(email-column-name|tonne-conversion-factor)$`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const storageId = Number((config.url as string).split('/')[4])
      const dto = JSON.parse(config.data as string) as EmailSyncDetails

      const prevStorage = scenario.burglengenfeld.materialStorage.find(
        (storage) => storage.id === storageId
      ) as MaterialStorage

      const editedStorage: MaterialStorage = {
        ...prevStorage,
        emailSyncDetails: dto,
        status: Status.Edited,
        updatedOn: moment.utc().toISOString(),
        updatedBy: 'Test user'
      }
      scenario.burglengenfeld.materialStorage = scenario.burglengenfeld.materialStorage.map(
        (storage) => (storage.id === storageId ? editedStorage : storage)
      )
      saveScenario(scenario)
      return sleepResponse([200, editedStorage])
    })

  // DELETE data connection (datamart and email-parser)
  mock
    .onDelete(
      new RegExp(
        `^/plants/${numberRegEx}/material-storage/${numberRegEx}/details/(datamart|email-parser)$`
      )
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const storageId = Number((config.url as string).split('/')[4])
      const connectionType = (config.url as string).split('/')[6] as DataConnectionType

      const prevStorage = scenario.burglengenfeld.materialStorage.find(
        (storage) => storage.id === storageId
      ) as MaterialStorage

      const editedStorage: MaterialStorage = {
        ...prevStorage,
        ...(connectionType === 'datamart' ? {datamartSyncDetails: null} : {emailSyncDetails: null}),
        status: Status.Edited,
        updatedOn: moment.utc().toISOString(),
        updatedBy: 'Test user'
      }
      scenario.burglengenfeld.materialStorage = scenario.burglengenfeld.materialStorage.map(
        (storage) => (storage.id === storageId ? editedStorage : storage)
      )
      saveScenario(scenario)

      return sleepResponse([200, {}])
    })

  return mock
}
