import React from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'

import {GuardedRoutes} from './GuardedRoutes'
import {PageForbidden, PageNotFound} from './modules/common/components'
import {PagePlant} from './modules/common/components/PagePlant'
import {DefaultPageRedirect} from './modules/common/redirects/DefaultPageRedirect'
import {EntryPointRedirect} from './modules/common/redirects/EntryPointRedirect'
import {getRelPath, ROUTES} from './modules/routing'

export const App: React.FC = () => {
  return (
    <Routes>
      <Route path={getRelPath(ROUTES.BASE.path)}>
        <Route path={getRelPath(ROUTES.BASE.PLANT_SELECTOR.path)}>
          {/* to select a plant we are doing redirect to HProduce Entry Point app */}
          <Route index element={<EntryPointRedirect />} />
          <Route path={getRelPath(ROUTES.BASE.PLANT_SELECTOR.PLANT.path)} element={<PagePlant />}>
            <Route index element={<DefaultPageRedirect />} />
            <Route path="*" element={<GuardedRoutes />} />
          </Route>
        </Route>
        <Route
          path=""
          element={<Navigate to={getRelPath(ROUTES.BASE.PLANT_SELECTOR.path)} replace />}
        />
        <Route path={getRelPath(ROUTES.BASE.NOT_FOUND.path)} element={<PageNotFound />} />
        <Route path={getRelPath(ROUTES.BASE.NOT_AUTHORIZED.path)} element={<PageForbidden />} />
        {/* No match route */}
        <Route
          path="*"
          element={<Navigate to={getRelPath(ROUTES.BASE.NOT_FOUND.path)} replace />}
        />
      </Route>
    </Routes>
  )
}
