import {
  BrowserBackendSelector,
  BrowserLoginFlow,
  BrowserLoginStorage,
  createHttpClient,
  EnvAuthRequestProvider,
  getDefaultDFBackends
} from '@hconnect/apiclient'
import {AxiosInstance} from 'axios'

export const plannerDSApiBasePath = '/hcem/ui/v2'

export type AsyncFn<P extends unknown[], R> = (...args: P) => Promise<R>

export class Api {
  static #axiosInstance: AxiosInstance | null = null
  static #loginStorage: BrowserLoginStorage | null = null
  static #loginFlow: BrowserLoginFlow | null = null
  private static initialize() {
    const backendSelector = new BrowserBackendSelector(getDefaultDFBackends())
    const selectedBackend = backendSelector.getSelectedBackend()

    Api.#loginStorage = new BrowserLoginStorage(
      `HC-${process.env.REACT_APP_CLIENT_NAME}-${selectedBackend.NAME}`
    )

    const dfAuthRequestProvider = new EnvAuthRequestProvider(backendSelector)
    Api.#loginFlow = new BrowserLoginFlow(Api.#loginStorage, dfAuthRequestProvider, backendSelector)

    // API for all service requests going through api gateway
    Api.#axiosInstance = createHttpClient({
      backendSelector: backendSelector,
      authRequestProvider: dfAuthRequestProvider,
      loginStorage: Api.#loginStorage,
      loginFlow: Api.#loginFlow
    })
  }

  public static get axiosInstance(): AxiosInstance {
    if (Api.#axiosInstance === null) Api.initialize()
    return Api.#axiosInstance as AxiosInstance
  }
  public static get loginFlow(): BrowserLoginFlow {
    if (Api.#loginFlow === null) Api.initialize()
    return Api.#loginFlow as BrowserLoginFlow
  }
}
