import type {
  AssetResponse,
  AssetPayload,
  AssetLink,
  AssetTransition,
  AssetTransitionDTO,
  OperationModeResponse,
  OperationModePayload,
  AssetEditableFields
} from '@hconnect/common/types'
import {Status, AssetLinkType, LinkDirection} from '@hconnect/common/types'
import MockAdapter from 'axios-mock-adapter'
import moment from 'moment-timezone'

import {getStandardOperationTimes} from '../../modules/assets'
import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

export const enableAssetsEndpoints = (mock: MockAdapter) => {
  // GET assets
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/assets$`)).reply(() => {
    const {
      burglengenfeld: {assets}
    } = mockStore.scenario()

    return sleepResponse([200, assets])
  })

  // POST add asset
  mock.onPost(new RegExp(`^/plants/${numberRegEx}/assets$`)).reply((config) => {
    const scenario = mockStore.scenario()
    const {type, name} = JSON.parse(config.data as string) as AssetPayload
    const newAsset: AssetResponse = {
      id: Date.now(),
      name,
      status: Status.Created,
      type,
      startupCost: 100,
      minimumDowntime: 0,
      isShutdownAvailable: true,
      isOptimized: false,
      operationModes: [],
      createdBy: 'Test Planner User',
      createdOn: moment.utc().toISOString(),
      startCoefficient: 1,
      stopCoefficient: 1
    }
    scenario.burglengenfeld.assets.push(newAsset)
    saveScenario(scenario)
    return sleepResponse([201, newAsset])
  })

  // PATCH edit asset name, type or is shutdown available
  const editAssetResourceRegEx =
    '(name|type|startup-cost|is-shutdown-available|start-coefficient|stop-coefficient)'

  mock
    .onPatch(new RegExp(`^/plants/${numberRegEx}/assets/${numberRegEx}/${editAssetResourceRegEx}$`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const assetId = Number((config.url as string).split('/')[4])
      const payload = JSON.parse(config.data as string) as Partial<
        Pick<AssetResponse, AssetEditableFields>
      >

      const editedAsset: AssetResponse = {
        ...(scenario.burglengenfeld.assets.find((asset) => asset.id === assetId) as AssetResponse),
        ...payload,
        updatedOn: moment.utc().toISOString()
      }

      scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
        if (asset.id === assetId) {
          return editedAsset
        }
        return asset
      })

      saveScenario(scenario)
      return sleepResponse([200, editedAsset])
    })

  // PATCH edit asset minimum downtime
  mock
    .onPatch(new RegExp(`^/plants/${numberRegEx}/assets/${numberRegEx}/minimum-downtime`))
    .reply((config) => {
      const scenario = mockStore.scenario()
      const assetId = Number((config.url as string).split('/')[4])
      const {minimumDowntime} = JSON.parse(config.data as string) as AssetResponse

      const editedAsset: AssetResponse = {
        ...(scenario.burglengenfeld.assets.find((asset) => asset.id === assetId) as AssetResponse),
        minimumDowntime,
        updatedOn: moment.utc().toISOString()
      }

      scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
        if (asset.id === assetId) {
          return editedAsset
        }
        return asset
      })

      saveScenario(scenario)
      return sleepResponse([200, editedAsset])
    })

  // DELETE asset
  mock.onDelete(new RegExp(`^/plants/${numberRegEx}/assets/${numberRegEx}$`)).reply((config) => {
    const scenario = mockStore.scenario()
    const assetId = Number((config.url as string).split('/')[4])

    scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.filter(
      (asset) => asset.id !== assetId
    )

    saveScenario(scenario)
    return sleepResponse([200, {}])
  })

  // GET Assets links
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/assets/${numberRegEx}/links$`)).reply((config) => {
    const assetId = Number((config.url as string).split('/')[4])
    const params = config.params as {fromTypes: string; toTypes: string}
    const fromTypes = params.fromTypes.split(',') as AssetLinkType[]
    const toTypes = params.toTypes.split(',') as AssetLinkType[]
    const {
      burglengenfeld: {assetsLinks}
    } = mockStore.scenario()

    // filtering using query params and assetId
    const filteredAssetLinks: AssetLink[] = assetsLinks
      .filter(({from, to}) => fromTypes.includes(from.type) && toTypes.includes(to.type))
      .filter(
        ({from, to}) =>
          (from.type === AssetLinkType.Asset && from.id === assetId) ||
          (to.type === AssetLinkType.Asset && to.id === assetId)
      )

    return sleepResponse([200, filteredAssetLinks])
  })

  // POST create an asset link
  mock.onPost(new RegExp(`^/plants/${numberRegEx}/assets/links$`)).reply((config) => {
    const scenario = mockStore.scenario()
    const dto = JSON.parse(config.data as string) as Pick<AssetLink, LinkDirection>

    const newAssetLink: AssetLink = {
      id: Date.now(),
      createdBy: 'Test Planner User',
      createdOn: moment.utc().toISOString(),
      ...dto
    }
    scenario.burglengenfeld.assetsLinks.push(newAssetLink)

    saveScenario(scenario)
    return sleepResponse([201, newAssetLink])
  })

  // PUT edit an asset link
  mock
    .onPatch(new RegExp(`^/plants/${numberRegEx}/assets/links/${numberRegEx}$`))
    .reply((config) => {
      const assetLinkId = Number((config.url as string).split('/')[5])
      const scenario = mockStore.scenario()

      const dto = JSON.parse(config.data as string) as Pick<AssetLink, LinkDirection>

      const assetLinkToEdit = scenario.burglengenfeld.assetsLinks.find(
        (link) => link.id === assetLinkId
      ) as AssetLink

      const editedAssetLink: AssetLink = {...assetLinkToEdit, ...dto}

      scenario.burglengenfeld.assetsLinks = scenario.burglengenfeld.assetsLinks.map((link) =>
        link.id === assetLinkId ? editedAssetLink : link
      )

      saveScenario(scenario)
      return sleepResponse([200, editedAssetLink])
    })

  // DELETE remove an asset link
  mock
    .onDelete(new RegExp(`^/plants/${numberRegEx}/assets/links/${numberRegEx}$`))
    .reply((config) => {
      const assetLinkId = Number((config.url as string).split('/')[5])
      const scenario = mockStore.scenario()

      scenario.burglengenfeld.assetsLinks = scenario.burglengenfeld.assetsLinks.filter(
        (link) => link.id !== assetLinkId
      )
      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // POST add an operation mode
  mock
    .onPost(new RegExp(`^/plants/${numberRegEx}/assets/${numberRegEx}/operation-modes$`))
    .reply((config) => {
      const assetId = Number((config.url as string).split('/')[4])
      const scenario = mockStore.scenario()
      const {recipeId, throughput, minimumRuntime, powerConsumption, name, type} = JSON.parse(
        config.data as string
      ) as OperationModePayload
      const operationMode: OperationModeResponse = {
        id: Date.now(),
        name,
        type,
        recipeId,
        throughput,
        minimumRuntime,
        powerConsumption,
        status: Status.Created,
        isOptimized: false
      }
      scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
        if (asset.id === assetId) {
          asset.operationModes.push(operationMode)
        }
        return asset
      })
      saveScenario(scenario)
      return sleepResponse([201, operationMode])
    })

  // PATCH edit an operation mode
  mock
    .onPatch(
      new RegExp(`/plants/${numberRegEx}/assets/${numberRegEx}/operation-modes/${numberRegEx}/*`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const assetId = Number((config.url as string).split('/')[4])
      const operationModeId = Number((config.url as string).split('/')[6])
      const dto = JSON.parse(config.data as string) as OperationModePayload

      const editedOperationMode: OperationModeResponse = {
        id: operationModeId,
        status: Status.Edited,
        ...dto
      }

      scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
        if (asset.id === assetId) {
          asset.operationModes = asset.operationModes.map((operationMode) =>
            operationMode.id === operationModeId ? editedOperationMode : operationMode
          )
        }
        return asset
      })
      saveScenario(scenario)
      return sleepResponse([200, editedOperationMode])
    })

  // DELETE operation mode
  mock
    .onDelete(
      new RegExp(`^/plants/${numberRegEx}/assets/${numberRegEx}/operation-modes/${numberRegEx}$`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const assetId = Number((config.url as string).split('/')[4])
      const operationModeId = Number((config.url as string).split('/')[6])

      scenario.burglengenfeld.assets = scenario.burglengenfeld.assets.map((asset) => {
        if (asset.id === assetId) {
          asset.operationModes = asset.operationModes.filter(
            (operationMode) => operationMode.id !== operationModeId
          )
        }
        return asset
      })

      // we should handle deleting standard operation times which are using deleted operation mode
      const standardOperationTimesForAsset =
        scenario.burglengenfeld.assetsStandardOperationTimes[assetId]
      const standardOperationTimesIdToDelete = getStandardOperationTimes(
        operationModeId,
        standardOperationTimesForAsset
      ).map(({id}) => id)

      if (standardOperationTimesIdToDelete.length > 0) {
        scenario.burglengenfeld.assetsStandardOperationTimes[assetId] =
          standardOperationTimesForAsset.filter((standardOperationTime) => {
            return !standardOperationTimesIdToDelete.includes(standardOperationTime.id)
          })
      }

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // GET assets transitions
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/assets/transitions$`)).reply(() => {
    const {
      burglengenfeld: {assetsTransitions}
    } = mockStore.scenario()

    return sleepResponse([200, assetsTransitions])
  })

  // PATCH edit asset transtion
  mock
    .onPatch(
      new RegExp(`/plants/${numberRegEx}/assets/${numberRegEx}/transitions/${numberRegEx}/*`)
    )
    .reply((config) => {
      const scenario = mockStore.scenario()
      const assetId = (config.url as string).split('/')[4]
      const transitionId = Number((config.url as string).split('/')[6])
      const {isPossible, time} = JSON.parse(config.data as string) as AssetTransitionDTO

      const prevAssetsTransitions = scenario.burglengenfeld.assetsTransitions
      const assetTransitions = prevAssetsTransitions[assetId]

      const prevTransition: AssetTransition = assetTransitions.find(
        (transition) => transition.id === transitionId
      ) as AssetTransition

      const editedTransition: AssetTransition = {
        ...prevTransition,
        isPossible,
        time
      }

      scenario.burglengenfeld.assetsTransitions = {
        ...prevAssetsTransitions,
        [assetId]: assetTransitions.map((transition) =>
          transition.id === transitionId ? editedTransition : transition
        )
      }

      saveScenario(scenario)
      return sleepResponse([200, editedTransition])
    })

  return mock
}
