import MockAdapter from 'axios-mock-adapter'
import {sortBy} from 'lodash'

import {mockStore} from '../mockStore'

import {sleepResponse, numberRegEx, saveScenario} from './utils'

import {SamplingPoint} from '@settings/modules/sampling-points/types'

export const enableSamplingPointsEndpoints = (mock: MockAdapter) => {
  // GET sampling point types
  mock.onGet(new RegExp('^/plants/materials/sampling-points/types$')).reply(() => {
    const {
      burglengenfeld: {samplingPointsTypes}
    } = mockStore.scenario()

    return sleepResponse([200, samplingPointsTypes])
  })

  // GET lims sampling points (mapped and unmapped)
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/lims/sampling-points$`)).reply(() => {
    const {
      burglengenfeld: {limsSamplingPoints}
    } = mockStore.scenario()

    return sleepResponse([200, limsSamplingPoints])
  })

  // GET already mapped sampling points info
  mock.onGet(new RegExp(`^/plants/${numberRegEx}/materials/sampling-points$`)).reply(() => {
    const {
      burglengenfeld: {samplingPoints}
    } = mockStore.scenario()

    return sleepResponse([200, samplingPoints])
  })

  // POST map lims sampling point with type and equipment
  mock.onPost(new RegExp(`^/plants/${numberRegEx}/materials/sampling-points$`)).reply((config) => {
    const scenario = mockStore.scenario()

    // Getting the provided payload
    const providedPayload = JSON.parse(config.data as string) as Omit<SamplingPoint, 'id'>

    // Getting the last id of already existing samplingPoints
    const sortedSamplingPoints = sortBy(scenario.burglengenfeld.samplingPoints.sort(), ['id'])

    // Adding info about new mapping
    scenario.burglengenfeld.samplingPoints.push({
      id: sortedSamplingPoints[sortedSamplingPoints.length - 1].id + 1,
      ...providedPayload
    })

    saveScenario(scenario)
    return sleepResponse([200, {}])
  })

  // DELETE unmap lims sampling point from type and equipment
  mock
    .onDelete(new RegExp(`^/plants/${numberRegEx}/materials/sampling-points/${numberRegEx}$`))
    .reply((config) => {
      const scenario = mockStore.scenario()

      // Getting sampling point id that is being deleted
      const deletingSamplingPointId = Number((config.url as string).split('/')[5])

      // Removing sampling point that was unmapped
      scenario.burglengenfeld.samplingPoints = scenario.burglengenfeld.samplingPoints.filter(
        (samplingPoint) => samplingPoint.id !== deletingSamplingPointId
      )

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  // PATCH update lims sampling point existing mapping
  mock
    .onPatch(new RegExp(`^/plants/${numberRegEx}/materials/sampling-points/${numberRegEx}$`))
    .reply((config) => {
      const scenario = mockStore.scenario()

      // Getting sampling point id that is being updated
      const updatingSamplingPointId = Number((config.url as string).split('/')[5])

      // Getting the provided payload
      const providedPayload = JSON.parse(config.data as string) as Omit<
        SamplingPoint,
        'id' | 'limsSamplingPointId'
      >

      // Updating sampling point
      scenario.burglengenfeld.samplingPoints = scenario.burglengenfeld.samplingPoints.map(
        (samplingPoint) => {
          if (samplingPoint.id === updatingSamplingPointId) {
            return {
              id: samplingPoint.id,
              limsSamplingPointId: samplingPoint.limsSamplingPointId,
              ...providedPayload
            }
          }
          return samplingPoint
        }
      )

      saveScenario(scenario)
      return sleepResponse([200, {}])
    })

  return mock
}
