import type {AssetResponse, OperationModeResponse} from '@hconnect/common/types'
import {Status, AssetType, OperationModeType} from '@hconnect/common/types'

const now = new Date('2023-01-16T17:47:33Z')
const assetIds: number[] = []
const operationIds: number[] = []
const createOperationMode = (
  id: number,
  power: number,
  type: OperationModeType,
  throughput?: number,
  recipeId?: number
): OperationModeResponse => {
  if (operationIds.includes(id)) {
    throw new Error('duplicate operation id in test data')
  }
  return {
    id,
    name: `operation mode with id ${id}`,
    type,
    powerConsumption: power,
    throughput: throughput ?? null,
    recipeId: recipeId ?? null,
    status: Status.Created,
    minimumRuntime: 0,
    isOptimized: false
  }
}
const createAsset = (
  {
    id,
    name,
    type,
    isOptimized = false,
    startupCost = 127
  }: {
    id: number
    name: string
    type: AssetType
    isOptimized?: boolean
    startupCost?: number
  },
  operationModes: OperationModeResponse[]
): AssetResponse => {
  const idExists = assetIds.includes(id)
  if (idExists) {
    throw new Error('duplicate asset id in test data')
  }
  return {
    id,
    type,
    name,
    status: Status.Created,
    startupCost,
    createdOn: new Date(now.getDate() + id).toISOString(),
    createdBy: 'Test user',
    minimumDowntime: 0,
    isShutdownAvailable: true,
    isOptimized,
    operationModes,
    startCoefficient: 1,
    stopCoefficient: 1
  }
}

const assets: AssetResponse[] = [
  createAsset({id: 1, name: 'ZM6', type: AssetType.CementMill, isOptimized: true}, [
    createOperationMode(13, 2, OperationModeType.Production, 100, 1),
    createOperationMode(17, 4, OperationModeType.Production, 50, 6),
    createOperationMode(18, 2.8, OperationModeType.Production, 78, 1),
    createOperationMode(19, 2.8, OperationModeType.Production, 52, 7),
    createOperationMode(20, 2.8, OperationModeType.Production, 68, 8),
    createOperationMode(21, 2.8, OperationModeType.Production, 107, 3),
    createOperationMode(22, 2.8, OperationModeType.Production, 52, 9),
    createOperationMode(23, 2.8, OperationModeType.Production, 77, 10),
    createOperationMode(24, 2.8, OperationModeType.Production, 83, 4),
    createOperationMode(25, 2.8, OperationModeType.Production, 78, 5),
    createOperationMode(76, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 31, name: 'Packerei', type: AssetType.Other}, [
    (createOperationMode(55, 0.3, OperationModeType.Production),
    createOperationMode(65, 0, OperationModeType.Maintenance))
  ]),
  createAsset({id: 39, name: 'ZM6-2', type: AssetType.CementMill, isOptimized: true}, [
    createOperationMode(170, 4, OperationModeType.Production, 50, 6),
    createOperationMode(180, 2.8, OperationModeType.Production, 78, 1),
    createOperationMode(190, 2.8, OperationModeType.Production, 52, 7),
    createOperationMode(200, 2.8, OperationModeType.Production, 68, 8),
    createOperationMode(210, 2.8, OperationModeType.Production, 107, 3),
    createOperationMode(220, 2.8, OperationModeType.Production, 52, 9),
    createOperationMode(230, 2.8, OperationModeType.Production, 77, 10),
    createOperationMode(240, 2.8, OperationModeType.Production, 83, 4),
    createOperationMode(250, 2.8, OperationModeType.Production, 78, 5),
    createOperationMode(760, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 30, name: 'BPG-Mühle', type: AssetType.Other}, [
    createOperationMode(52, 1, OperationModeType.Maintenance), // previously production
    createOperationMode(64, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 29, name: 'Kohlenmühle', type: AssetType.Other}, [
    createOperationMode(51, 1.3, OperationModeType.Maintenance), // previous production
    createOperationMode(63, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 42, name: 'RM1', type: AssetType.RawMill, isOptimized: true}, [
    createOperationMode(36, 3.9, OperationModeType.Production, 214, 13),
    createOperationMode(37, 3.9, OperationModeType.Production, 171, 12),
    createOperationMode(68, 0, OperationModeType.Maintenance)
  ]),
  // TODO check there's no crusher type but the original was a crusher
  createAsset({id: 26, name: 'Crusher', type: AssetType.Other}, [
    createOperationMode(50, 1.7, OperationModeType.Maintenance), // previously production
    createOperationMode(60, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 32, name: 'Schottertr.', type: AssetType.Other}, [
    createOperationMode(54, 0.4, OperationModeType.Maintenance), // previously production
    createOperationMode(66, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 40, name: 'ZM7', type: AssetType.CementMill, isOptimized: true}, [
    createOperationMode(26, 1.9, OperationModeType.Production, 40, 6),
    createOperationMode(27, 1.9, OperationModeType.Production, 90, 1),
    createOperationMode(28, 1.9, OperationModeType.Production, 83, 7),
    createOperationMode(29, 1.9, OperationModeType.Production, 87, 8),
    createOperationMode(30, 1.9, OperationModeType.Production, 130, 3),
    createOperationMode(31, 1.9, OperationModeType.Production, 50, 9),
    createOperationMode(32, 1.9, OperationModeType.Production, 90, 11),
    createOperationMode(33, 1.9, OperationModeType.Production, 106, 10),
    createOperationMode(34, 1.9, OperationModeType.Production, 90, 5),
    createOperationMode(77, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 27, name: 'WT01', type: AssetType.RotaryKiln}, [
    createOperationMode(53, 1, OperationModeType.Production, -100, 13),
    createOperationMode(902, 5.2, OperationModeType.Production, -50, 12),
    createOperationMode(903, 4, OperationModeType.Production, 40, 14),
    createOperationMode(61, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 43, name: 'RM2', type: AssetType.RawMill}, [
    createOperationMode(38, 3.9, OperationModeType.Production, 209, 13),
    createOperationMode(69, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 38, name: 'ZM5', type: AssetType.CementMill}, [
    createOperationMode(1300, 2, OperationModeType.Production, 100, 1),
    createOperationMode(14, 2.5, OperationModeType.Production, 112, 3),
    createOperationMode(15, 2.5, OperationModeType.Production, 87, 4),
    createOperationMode(16, 2.5, OperationModeType.Production, 80, 5),
    createOperationMode(75, 0, OperationModeType.Maintenance)
  ]),
  createAsset({id: 909, name: 'BaseLoad', type: AssetType.BaseLoad}, [
    createOperationMode(1900, 2.5, OperationModeType.Production, undefined, undefined),
    createOperationMode(1901, 0, OperationModeType.Maintenance)
  ])
]

// eslint-disable-next-line import/no-default-export
export default assets
