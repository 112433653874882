import {AppInsightsAdapter} from '@hconnect/common/logging'
import {ErrorBoundary, hpTheme} from '@hconnect/uikit/src/lib2'
import {ThemeProvider} from '@mui/material'
import React, {Suspense} from 'react'
import {createRoot} from 'react-dom/client'
import {BrowserRouter as Router} from 'react-router-dom'

import {checkAndEnableMockIfRequired} from './__mock__/registerMocks'
import {App} from './App'
import {initLocalization} from './localization'

import {PageSkeleton} from '@settings/modules/common/components'
import {GlobalProvider} from '@settings/modules/common/providers'

initLocalization()
checkAndEnableMockIfRequired()
AppInsightsAdapter.init()
// Tracking information and logs will be sent to azure
AppInsightsAdapter.allowTracking()

const rootElement = document.getElementById('root')
const root = createRoot(rootElement as HTMLElement)

root.render(
  <ThemeProvider theme={hpTheme}>
    <ErrorBoundary
      apology="We're sorry, something went wrong."
      resetText="Click here to start over"
    >
      <Router>
        <Suspense fallback={<PageSkeleton />}>
          <GlobalProvider>
            <App />
          </GlobalProvider>
        </Suspense>
      </Router>
    </ErrorBoundary>
  </ThemeProvider>
)
